export default ({
  "Keros": {
    "title": "KEROS",
    "text": "Manage your invoices in a few seconds. Monitor your sales and your Mentee’s sales.",
    "button": "Get started"
  },
  "FormezVous": {
    "title": "Train Yourself",
    "text": "The Sextant e-learning site"
  },
  "VirtualTours": {
    "title": "Sextant Virtual Tours",
    "text": "Our site to help you create your virtual tours"
  },
  "PigeSMS": {
    "title": "SMS Notifications",
    "text": "Automated SMS to private sellers thanks to a smart robot"
  },
  "MyAdapt": {
    "title": "MyAdapt",
    "text": "Adapt Immo in full web version!"
  },
  "extranet": {
    "title": "Extranet Sextant",
    "text": "All the estate agent documents you need with your clients"
  },
  "LeonardAnalytics": {
    "title": "Leonard Analytics",
    "text": "Analytic tool for you and your clients"
  },
  "MyNotary": {
    "title": "MyNotary",
    "text": "Tool to prepare and sign mandates"
  },
  "PriceHubble": {
    "title": "PriceHubble",
    "text": "Advanced property valuation tool"
  },
  "TakTikimmo": {
    "title": "TakTikimmo",
    "text": "Advanced seller’s prospection tool"
  },
  "Vistaprint": {
    "title": "Vistaprint",
    "text": "Print your Sextant communication support"
  },
  "Imprimerie-immo": {
    "title": "Imprimerie-immo.com",
    "text": "Premium printing company for your communication supports"
  },
  "facebook": {
    "title": "Facebook agent’s group",
    "text": "Sextant internal group on Facebook (private)"
  },

  "googleMyBusiness": {
    "title": "Google My Business",
    "text": "Manage online presence across Google and Google Maps"
  },
  "Trustpilot": {
    "title": "Trustpilot",
    "text": "A review platform open to everyone"
  },
  "Immodvisor": {
    "title": "Immodvisor",
    "text": "Find trustworthy professionals"
  },

  "Meero": {
    "title": "Meero",
    "text": "Photos & videos done by a professional"
  },
  "Matterport": {
    "title": "Matterport",
    "text": "Virtual visits in 3D with a dollhouse"
  },
  "Solen": {
    "title": "Solen",
    "text": "To measure the sunlight for each property "
  },

  "Toolimo": {
    "title": "Toolimo",
    "text": "French network to obtain EPC reports"
  },
  "ArtemisCourtage": {
    "title": "Artémis Courtage",
    "text": "Tailor made real estate mortgage broker"
  },

  "groups": {
    "customers": "CUSTOMER’S REVIEWS",
    "partners": "OUR PARTNERS"
  },
  "coming-soon": "Coming soon"
});
